if (!window.Silverlight) {
    window.Silverlight = {};
}

// Silverlight control instance counter for memory mgt
Silverlight._silverlightCount = 0;
Silverlight.fwlinkRoot = 'http://go2.microsoft.com/fwlink/?LinkID=';
Silverlight.onGetSilverlight = null;
Silverlight.onSilverlightInstalled = function () { window.location.reload(false); };

//////////////////////////////////////////////////////////////////
// isInstalled, checks to see if the correct version is installed
//////////////////////////////////////////////////////////////////
Silverlight.isInstalled = function (version) {
    var isVersionSupported = false;
    var container = null;

    try {
        var control = null;

        try {
            control = new ActiveXObject('AgControl.AgControl');
            if (version == null) {
                isVersionSupported = true;
            }
            else if (control.IsVersionSupported(version)) {
                isVersionSupported = true;
            }
            control = null;
        }
        catch (e) {
            var plugin = navigator.plugins["Silverlight Plug-In"];
            if (plugin) {
                if (version === null) {
                    isVersionSupported = true;
                }
                else {
                    var actualVer = plugin.description;
                    if (actualVer === "1.0.30226.2")
                        actualVer = "2.0.30226.2";
                    var actualVerArray = actualVer.split(".");
                    while (actualVerArray.length > 3) {
                        actualVerArray.pop();
                    }
                    while (actualVerArray.length < 4) {
                        actualVerArray.push(0);
                    }
                    var reqVerArray = version.split(".");
                    while (reqVerArray.length > 4) {
                        reqVerArray.pop();
                    }

                    var requiredVersionPart;
                    var actualVersionPart
                    var index = 0;


                    do {
                        requiredVersionPart = parseInt(reqVerArray[index]);
                        actualVersionPart = parseInt(actualVerArray[index]);
                        index++;
                    }
                    while (index < reqVerArray.length && requiredVersionPart === actualVersionPart);

                    if (requiredVersionPart <= actualVersionPart && !isNaN(requiredVersionPart)) {
                        isVersionSupported = true;
                    }
                }
            }
        }
    }
    catch (e) {
        isVersionSupported = false;
    }
    if (container) {
        document.body.removeChild(container);
    }

    return isVersionSupported;
}
Silverlight.WaitForInstallCompletion = function () {
    if (!Silverlight.isBrowserRestartRequired && Silverlight.onSilverlightInstalled) {
        try {
            navigator.plugins.refresh();
        }
        catch (e) {
        }
        if (Silverlight.isInstalled(null)) {
            Silverlight.onSilverlightInstalled();
        }
        else {
            setTimeout(Silverlight.WaitForInstallCompletion, 3000);
        }
    }
}
Silverlight.__startup = function () {
    Silverlight.isBrowserRestartRequired = Silverlight.isInstalled(null); //(!window.ActiveXObject || Silverlight.isInstalled(null));
    if (!Silverlight.isBrowserRestartRequired) {
        Silverlight.WaitForInstallCompletion();
    }
    if (window.removeEventListener) {
        window.removeEventListener('load', Silverlight.__startup, false);
    }
    else {
        window.detachEvent('onload', Silverlight.__startup);
    }
}

if (window.addEventListener) {
    window.addEventListener('load', Silverlight.__startup, false);
}
else {
    window.attachEvent('onload', Silverlight.__startup);
}

///////////////////////////////////////////////////////////////////////////////
// createObject();  Params:
// parentElement of type Element, the parent element of the Silverlight Control
// source of type String
// id of type string
// properties of type String, object literal notation { name:value, name:value, name:value},
//     current properties are: width, height, background, framerate, isWindowless, enableHtmlAccess, inplaceInstallPrompt:  all are of type string
// events of type String, object literal notation { name:value, name:value, name:value},
//     current events are onLoad onError, both are type string
// initParams of type Object or object literal notation { name:value, name:value, name:value}
// userContext of type Object
/////////////////////////////////////////////////////////////////////////////////

Silverlight.createObject = function (source, parentElement, id, properties, events, initParams, userContext) {
    var slPluginHelper = new Object();
    var slProperties = properties;
    var slEvents = events;

    slPluginHelper.version = slProperties.version;
    slProperties.source = source;
    slPluginHelper.alt = slProperties.alt;

    //rename properties to their tag property names
    if (initParams)
        slProperties.initParams = initParams;
    if (slProperties.isWindowless && !slProperties.windowless)
        slProperties.windowless = slProperties.isWindowless;
    if (slProperties.framerate && !slProperties.maxFramerate)
        slProperties.maxFramerate = slProperties.framerate;
    if (id && !slProperties.id)
        slProperties.id = id;

    // remove elements which are not to be added to the instantiation tag
    delete slProperties.ignoreBrowserVer;
    delete slProperties.inplaceInstallPrompt;
    delete slProperties.version;
    delete slProperties.isWindowless;
    delete slProperties.framerate;
    delete slProperties.data;
    delete slProperties.src;
    delete slProperties.alt;


    // detect that the correct version of Silverlight is installed, else display install

    if (Silverlight.isInstalled(slPluginHelper.version)) {
        //move unknown events to the slProperties array
        for (var name in slEvents) {
            if (slEvents[name]) {
                if (name == "onLoad" && typeof slEvents[name] == "function" && slEvents[name].length != 1) {
                    var onLoadHandler = slEvents[name];
                    slEvents[name] = function (sender) { return onLoadHandler(document.getElementById(id), userContext, sender) };
                }
                var handlerName = Silverlight.__getHandlerName(slEvents[name]);
                if (handlerName != null) {
                    slProperties[name] = handlerName;
                    slEvents[name] = null;
                }
                else {
                    throw "typeof events." + name + " must be 'function' or 'string'";
                }
            }
        }
        slPluginHTML = Silverlight.buildHTML(slProperties);
    }
    //The control could not be instantiated. Show the installation prompt
    else {
        slPluginHTML = Silverlight.buildPromptHTML(slPluginHelper);
    }

    // insert or return the HTML
    if (parentElement) {
        parentElement.innerHTML = slPluginHTML;
    }
    else {
        return slPluginHTML;
    }

}

///////////////////////////////////////////////////////////////////////////////
//
//  create HTML that instantiates the control
//
///////////////////////////////////////////////////////////////////////////////
Silverlight.buildHTML = function (slProperties) {
    var htmlBuilder = [];

    htmlBuilder.push('<object type=\"application/x-silverlight\" data="data:application/x-silverlight,"');
    if (slProperties.id != null) {
        htmlBuilder.push(' id="' + slProperties.id + '"');
    }
    if (slProperties.width != null) {
        htmlBuilder.push(' width="' + slProperties.width + '"');
    }
    if (slProperties.height != null) {
        htmlBuilder.push(' height="' + slProperties.height + '"');
    }
    htmlBuilder.push(' >');

    delete slProperties.id;
    delete slProperties.width;
    delete slProperties.height;

    for (var name in slProperties) {
        if (slProperties[name]) {
            htmlBuilder.push('<param name="' + Silverlight.HtmlAttributeEncode(name) + '" value="' + Silverlight.HtmlAttributeEncode(slProperties[name]) + '" />');
        }
    }
    htmlBuilder.push('<\/object>');
    return htmlBuilder.join('');
}




// createObjectEx, takes a single parameter of all createObject parameters enclosed in {}
Silverlight.createObjectEx = function (params) {
    var parameters = params;
    var html = Silverlight.createObject(parameters.source, parameters.parentElement, parameters.id, parameters.properties, parameters.events, parameters.initParams, parameters.context);
    if (parameters.parentElement == null) {
        return html;
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////
// Builds the HTML to prompt the user to download and install Silverlight
///////////////////////////////////////////////////////////////////////////////////////////////
Silverlight.buildPromptHTML = function (slPluginHelper) {
    var slPluginHTML = "";
    var urlRoot = Silverlight.fwlinkRoot;
    var shortVer = slPluginHelper.version;
    if (slPluginHelper.alt) {
        slPluginHTML = slPluginHelper.alt;
    }
    else {
        if (!shortVer) {
            shortVer = "";
        }
        slPluginHTML = "<a href='javascript:Silverlight.getSilverlight(\"{1}\");' style='text-decoration: none;'><img src='{2}' alt='Get Microsoft Silverlight' style='border-style: none'/></a>";
        slPluginHTML = slPluginHTML.replace('{1}', shortVer);
        slPluginHTML = slPluginHTML.replace('{2}', urlRoot + '108181');
    }

    return slPluginHTML;
}


Silverlight.getSilverlight = function (version) {
    if (Silverlight.onGetSilverlight) {
        Silverlight.onGetSilverlight();
    }

    var shortVer = "";
    var reqVerArray = String(version).split(".");
    if (reqVerArray.length > 1) {
        var majorNum = parseInt(reqVerArray[0]);
        if (isNaN(majorNum) || majorNum < 2) {
            shortVer = "1.0";
        }
        else {
            shortVer = reqVerArray[0] + '.' + reqVerArray[1];
        }
    }

    var verArg = "";

    if (shortVer.match(/^\d+\056\d+$/)) {
        verArg = "&v=" + shortVer;
    }

    Silverlight.followFWLink("114576" + verArg);
}


///////////////////////////////////////////////////////////////////////////////////////////////
/// Navigates to a url based on fwlinkid
///////////////////////////////////////////////////////////////////////////////////////////////
Silverlight.followFWLink = function (linkid) {
    top.location = Silverlight.fwlinkRoot + String(linkid);
}












///////////////////////////////////////////////////////////////////////////////////////////////
/// Encodes special characters in input strings as charcodes
///////////////////////////////////////////////////////////////////////////////////////////////
Silverlight.HtmlAttributeEncode = function (strInput) {
    var c;
    var retVal = '';

    if (strInput == null) {
        return null;
    }

    for (var cnt = 0; cnt < strInput.length; cnt++) {
        c = strInput.charCodeAt(cnt);

        if (((c > 96) && (c < 123)) ||
                  ((c > 64) && (c < 91)) ||
                  ((c > 43) && (c < 58) && (c != 47)) ||
                  (c == 95)) {
            retVal = retVal + String.fromCharCode(c);
        }
        else {
            retVal = retVal + '&#' + c + ';';
        }
    }

    return retVal;
}
///////////////////////////////////////////////////////////////////////////////
//
//  Default error handling function to be used when a custom error handler is
//  not present
//
///////////////////////////////////////////////////////////////////////////////

Silverlight.default_error_handler = function (sender, args) {
    var iErrorCode;
    var errorType = args.ErrorType;

    iErrorCode = args.ErrorCode;

    var errMsg = "\nSilverlight error message     \n";

    errMsg += "ErrorCode: " + iErrorCode + "\n";


    errMsg += "ErrorType: " + errorType + "       \n";
    errMsg += "Message: " + args.ErrorMessage + "     \n";

    if (errorType == "ParserError") {
        errMsg += "XamlFile: " + args.xamlFile + "     \n";
        errMsg += "Line: " + args.lineNumber + "     \n";
        errMsg += "Position: " + args.charPosition + "     \n";
    }
    else if (errorType == "RuntimeError") {
        if (args.lineNumber != 0) {
            errMsg += "Line: " + args.lineNumber + "     \n";
            errMsg += "Position: " + args.charPosition + "     \n";
        }
        errMsg += "MethodName: " + args.methodName + "     \n";
    }
    alert(errMsg);
}

///////////////////////////////////////////////////////////////////////////////////////////////
/// Releases event handler resources when the page is unloaded
///////////////////////////////////////////////////////////////////////////////////////////////
Silverlight.__cleanup = function () {
    for (var i = Silverlight._silverlightCount - 1; i >= 0; i--) {
        window['__slEvent' + i] = null;
    }
    Silverlight._silverlightCount = 0;
    if (window.removeEventListener) {
        window.removeEventListener('unload', Silverlight.__cleanup, false);
    }
    else {
        window.detachEvent('onunload', Silverlight.__cleanup);
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////
/// Releases event handler resources when the page is unloaded
///////////////////////////////////////////////////////////////////////////////////////////////
Silverlight.__getHandlerName = function (handler) {
    var handlerName = "";
    if (typeof handler == "string") {
        handlerName = handler;
    }
    else if (typeof handler == "function") {
        if (Silverlight._silverlightCount == 0) {
            if (window.addEventListener) {
                window.addEventListener('onunload', Silverlight.__cleanup, false);
            }
            else {
                window.attachEvent('onunload', Silverlight.__cleanup);
            }
        }
        var count = Silverlight._silverlightCount++;
        handlerName = "__slEvent" + count;

        window[handlerName] = handler;
    }
    else {
        handlerName = null;
    }
    return handlerName;
}